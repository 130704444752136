/*
 |-----------------------------------------------------------------------------
 | components/molecules/SocialMedia/SocialMedia.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React from 'react';

import { get, map, toLower } from 'lodash';
// import { ray } from 'node-ray/web';
import classNames from 'classnames';

import * as ISocialMedia from './types';

import styles from './SocialMedia.module.scss';

const variants = {
	body: 'body',
	footer: 'footer',
	header: 'header',
};

const SocialMedia: React.FC<ISocialMedia.IProps> = ({
	className,
	hideIcons,
	platforms,
	variant,
}) => {
	// ray('Debug molecule SocialMedia:', {
	// 	classname: classname,
	// 	hideIcons: hideIcons,
	// 	platforms: platforms,
	// 	variant: variant,
	// }).blue();

	const classes = classNames(get(styles, variants[variant]), className);

	return (
		<ul className={classes}>
			{map(platforms, (platform, index) => (
				<li key={index}>
					<a
						className={get(styles, 'platform')}
						href={get(platform, 'url')}
						rel="external noopener noreferrer"
						target="_blank"
						title={`Visit ${get(platform, 'name')}`}
					>
						{hideIcons ? (
							get(platform, 'name')
						) : (
							<svg
								className={get(styles, 'icon')}
								aria-hidden="true"
							>
								<use
									xlinkHref={`/imgs/icn_sprite.svg#${toLower(
										get(platform, 'name')
									)}`}
								></use>
							</svg>
						)}

						<svg
							className={get(styles, 'external')}
							aria-hidden="true"
						>
							<use
								xlinkHref={`/imgs/icn_sprite.svg#external`}
							></use>
						</svg>
					</a>
				</li>
			))}
		</ul>
	);
};

export default SocialMedia;
